import React, { useState, useCallback } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { A11y, Autoplay, Keyboard, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import EffectCarousel from './effect-carousel.esm.js';

//Function Imports

import { GatsbyImage } from 'gatsby-plugin-image';
import { PreHeader, P2 } from '../../theme/typography';
import BreathingPlayButton from '../breathingPlayButton';
import VideoModal from '../videoModal';

//Styled Components
const StyledSwiper = styled(Swiper)`
  --border-radius: 8px;

  position: relative;
  user-select: none;
  box-sizing: border-box;
  /* overflow: hidden; */
  width: 100%;
  max-width: 1200px;

  height: 200px;
  margin: 0 auto;

  //! Have to style SwiperSlide here as I believe swiperJS grabs element by name
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    width: 85%;
    height: 100%;
    background-color: black;
    border-radius: var(--border-radius);
  }

  @media (min-width: 425px) {
    height: 275px;
  }

  @media (min-width: 600px) {
    height: 300px;

    .swiper-slide {
      width: 70%;
    }
  }

  @media (min-width: 800px) {
    height: 350px;

    .swiper-slide {
      width: 60%;
    }
  }

  @media (min-width: 1000px) {
    height: 400px;
    .swiper-slide {
      width: 50%;
    }
  }
`;

const SlideImage = styled(GatsbyImage)`
  border-radius: var(--border-radius);
  position: absolute;
  object-fit: cover;
  inset: 0;

  z-index: 0;
`;

const SlideContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: 16px 24px;

  align-items: flex-start;
  justify-content: flex-end;

  @media (min-width: 425px) {
    padding: 32px;
  }
`;

const GradientDiv = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    0deg,
    rgba(8, 39, 58, 0.9) 0%,
    rgba(255, 255, 255, 0) 65%
  );
  border-radius: var(--border-radius);
  z-index: -1;
`;

const ClientTitle = styled(PreHeader)`
  color: var(--accent);
  line-height: 24px;
`;

const VideoTitle = styled(P2)`
  color: var(--white);
`;

const PlayButtonWrapper = styled.div`
  --bubble-size: 32px;
  --icon-size: 16px;
  padding: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  @media (min-width: 425px) {
    --bubble-size: 48px;
    --icon-size: 24px;
  }
`;

const CarouselNav = styled.div`
  display: flex;
  justify-content: center;
  gap: 48px;
  margin-top: 32px;
`;

const IconBubble = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--swiper-navigation-bubble-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.7;
  }
`;

const IconSVG = styled.svg`
  width: 24px;
  height: 24px;
  stroke: var(--swiper-navigation-icon-color);
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const PrevButton = styled(IconBubble)``;
const NextButton = styled(IconBubble)``;

const PlayButton = ({ index, activeIndex, toggleModal }) => {
  const swiper = useSwiper();
  return (
    <PlayButtonWrapper
      onClick={() => {
        swiper.autoplay.stop();
        toggleModal();
      }}
    >
      <BreathingPlayButton animating={index == activeIndex} />
    </PlayButtonWrapper>
  );
};

const VideoCarousel = ({ videos, productRoutes, solutionRoutes }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const swiperParameters = {
    modules: [A11y, Autoplay, Keyboard, Navigation, EffectCarousel],
    slidesPerView: 'auto',
    centeredSlides: true,
    grabCursor: true,
    loop: true,
    effect: 'carousel',
    navigation: {
      enabled: true,
      prevEl: '.prevButton',
      nextEl: '.nextButton',
    },
    autoplay: {
      enabled: true,
      pauseOnMouseEnter: true,
      disableOnInteraction: true,
    },
    keyboard: { enabled: true, pageUpDown: false },
    watchSlidesProgress: true,
  };
  return (
    <>
      <StyledSwiper
        {...swiperParameters}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.realIndex);
        }}
      >
        {videos.map((video, index) => (
          <SwiperSlide key={index}>
            <SlideImage
              image={video.thumbnail.asset.gatsbyImageData}
              alt={`${video.title}`}
              className={'swiper-carousel-animate-opacity'}
            />
            <SlideContent className={'swiper-carousel-animate-opacity'}>
              <ClientTitle>
                {video.client.company && `${video.client.company}`}
              </ClientTitle>
              <VideoTitle>{video.title && `${video.title}`}</VideoTitle>
              <GradientDiv />
              <PlayButton
                index={index}
                activeIndex={activeIndex}
                toggleModal={toggleModal}
              />
            </SlideContent>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <VideoModal
        isModalOpen={isModalOpen}
        video={videos[activeIndex]}
        productRoutes={productRoutes}
        solutionRoutes={solutionRoutes}
        onClose={() => setIsModalOpen(false)}
      />
      <CarouselNav>
        <PrevButton arrow className='prevButton'>
          <IconSVG>
            <use href={`/icons/feather-sprite.svg#arrow-left`} />
          </IconSVG>
        </PrevButton>
        <NextButton arrow className='nextButton'>
          <IconSVG>
            <use href={`/icons/feather-sprite.svg#arrow-right`} />
          </IconSVG>
        </NextButton>
      </CarouselNav>
    </>
  );
};

export default VideoCarousel;

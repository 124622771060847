import React from 'react';
import styled from 'styled-components';
import BottomCut from './bottomCut';
import { GatsbyImage } from 'gatsby-plugin-image';

const ImageDiv = styled.div`
  position: relative;
  /* overflow: hidden; */
  width: 100%;
  height: calc(168px + 4vw);

  @media (min-width: 1024px) {
    height: 288px;
  }

  @media (min-width: 1024px) {
    height: calc(288px + 4vw);
  }
`;

export const TopCut = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  /* Setting height by viewport width maintains angle of slope on screen resize */
  height: 8vw;
  z-index: 2;

  > svg {
    fill: ${(props) =>
      (props.light && props.theme.color.white) ||
      (props.dark && props.theme.color.blue1) ||
      props.theme.color.blue6};
  }
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const FooterImageStripe = ({ image, light, dark, centered }) => {
  return (
    <ImageDiv>
      <TopCut light={light} dark={dark}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
          width='100%'
          height='100%'
        >
          <polygon points='0,0 0,100 100,0' />
        </svg>
      </TopCut>
      <Image
        image={image.asset.gatsbyImageData}
        objectPosition={centered ? '' : '50% 15%'}
        alt=''
      />
      <BottomCut dark />
    </ImageDiv>
  );
};

export default FooterImageStripe;

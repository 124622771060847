import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section, TwoCols, TwoCol } from '../components/page-elements';
import PrimaryHeaderBlock from '../components/primaryHeaderBlock';
import CenteredTextBlock from '../components/centeredTextBlock';
import CTABlock from '../components/ctaBlock';
import BottomCut from '../components/bottomCut';
import AnimatedStripe from '../components/animatedStripe';
import Spacer from '../components/spacer';
import { ButtonText } from '../theme/typography';
import LogoGrid from '../components/logoGrid';
import VideoCarousel from '../components/video-carousel/videoCarousel';
import VideoGrid from '../components/videoGrid';
import ImageStripe from '../components/imageStripe';

// Asset Imports
import Stripes02SVG from '../images/stripes/stripes02.inline.svg';
import Stripes03SVG from '../images/stripes/stripes03.inline.svg';
import Stripes08SVG from '../images/stripes/stripes08.inline.svg';

// Function Imports
import shuffleArray from '../helpers/shuffleArray';

// Styled Components
const BioCol = styled(TwoCol)`
  @media (min-width: 1024px) {
    flex-basis: 60%;
    flex-grow: 1;
  }
`;

const HeadshotCol = styled(TwoCol)`
  padding-top: 48px;

  @media (min-width: 1024px) {
    /* padding-top: 64px;
    padding-top: 32px; */
    padding-top: 0;
    flex-basis: 40%;
    flex-grow: 1;
  }
`;

const DirectorHeaderBlock = styled(PrimaryHeaderBlock)`
  max-width: 650px;

  h2 {
    font-size: var(--font-size-SH);
    text-transform: uppercase;
  }
`;

const Pill = styled(ButtonText)`
  padding: 0 16px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 3px;
  border-radius: 16.5px;
  white-space: nowrap;
  color: var(--blue1);
  background: var(--blue6);

  @media (min-width: 1024px) {
    margin-left: auto;
  }
`;

const HeadshotWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 500px;
`;

const HeadshotContent = styled.div`
  max-width: 392px;
  max-height: 100%;

  aspect-ratio: 392 / 512;
  object-fit: cover;
  margin: 0 auto;
  clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
  position: relative;
`;

const Headshot = styled(GatsbyImage)`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  inset: 0;
  object-fit: cover;
  filter: grayscale(100%);
`;

const HeadshotStripes01 = styled(AnimatedStripe)`
  position: absolute;
  width: 150%;
  height: 100%;
  top: -28%;
  left: -20%;
  pointer-events: none;

  @media (min-width: 500px) {
    left: -35%;
  }

  path:first-of-type {
    transform: translate(50px, 50px);
  }
`;

const HeadshotStripes02 = styled(AnimatedStripe)`
  position: absolute;
  width: 70%;
  height: 40%;
  bottom: -10%;
  right: -15%;
  pointer-events: none;

  > svg > path:nth-child(1) {
    fill: var(--blue5) !important;
  }
`;

const HeadshotLoopWrapper = styled.div`
  display: none;
  position: absolute;
  object-fit: cover;
  inset: 0;
  z-index: 1;
  transition: opacity 0.5s ease;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`;

const HeadshotLoop = styled.video`
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const PortfolioStripes = styled(AnimatedStripe)`
  position: absolute;
  width: 90%;
  height: 20%;
  top: -100px;
  right: -20%;

  @media (min-width: 600px) {
    width: 60%;
    height: 70%;
    top: -100px;
    right: -20%;
  }

  @media (min-width: 1000px) {
    width: 35%;
    height: 70%;
    top: -200px;
    right: -20%;
  }
`;

const PortfolioWrapper = styled.div`
  position: relative;
`;

const DirectorPage = ({ pageContext, data }) => {
  const [isLoopPlaying, setIsLoopPlaying] = useState(false);

  const {
    slug,
    firstName,
    lastName,
    nickName,
    relationship,
    bio,
    headshot,
    awards,
    featuredWork,
    clients,
    sizzleLoop,
    footerImage,
    centeredFooterImage,
  } = data.sanityDirector;
  const portfolio = shuffleArray(data.allSanityVideo.nodes);

  return (
    <>
      <SEO title={`${firstName} ${lastName ? lastName : ''}`} />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section
        light
        style={{ marginTop: 'var(--site-header-height)', overflowX: 'clip' }}
      >
        <Container>
          <TwoCols reverse>
            <BioCol>
              {relationship == 'represented' && (
                <>
                  <Pill>Rubberduck Exclusive</Pill>
                  <Spacer size={24} />
                </>
              )}
              <DirectorHeaderBlock
                data={{
                  preHeader:
                    firstName[firstName.length - 1] == '&'
                      ? 'Directing Duo'
                      : 'Director',
                  title: `${firstName} ${lastName ? lastName : ''}`,
                  body: bio,
                  primaryCTA: true,
                  primaryCTAData: {
                    label: `Work with ${nickName ? nickName : firstName}`,
                    type: 'primary',
                    arrow: true,
                    path: '/work-with-director',
                    state: {
                      directorSlug: slug,
                    },
                  },
                }}
              />
            </BioCol>
            <HeadshotCol>
              <HeadshotWrapper>
                <HeadshotStripes01 direction='rightAndUp'>
                  <Stripes02SVG style={{ transform: `scaleX(-1)` }} />
                </HeadshotStripes01>

                <HeadshotContent>
                  {sizzleLoop && (
                    <HeadshotLoopWrapper>
                      <HeadshotLoop
                        onMouseOver={(e) =>
                          e.target.play().then(() => setIsLoopPlaying(true))
                        }
                        onMouseOut={(e) => {
                          if (isLoopPlaying) {
                            e.target.pause();
                          }
                        }}
                        muted
                        loop
                        playsInline
                        preload='none'
                        poster={sizzleLoop.poster.asset.url}
                      >
                        <source
                          src={sizzleLoop.webm.asset.url}
                          type='video/webm'
                        />
                        <source
                          src={sizzleLoop.mp4.asset.url}
                          type='video/mp4'
                        />
                      </HeadshotLoop>
                    </HeadshotLoopWrapper>
                  )}
                  <Headshot
                    image={headshot.asset.gatsbyImageData}
                    alt={`${firstName} ${lastName ? lastName : ''}`}
                  />
                </HeadshotContent>
                <HeadshotStripes02 direction='rightAndUp'>
                  <Stripes03SVG style={{ transform: `scaleX(-1)` }} />
                </HeadshotStripes02>
              </HeadshotWrapper>
            </HeadshotCol>
          </TwoCols>
          {awards.length > 0 && (
            <>
              <Spacer size={128} />
              <LogoGrid clients={awards} />
              <Spacer size={80} />
            </>
          )}
        </Container>
        <BottomCut dark />
      </Section>
      <Section dark cuts='topAndBottomCut'>
        <CenteredTextBlock
          data={{ preHeader: 'Featured Work', title: `A Few of the Best Bits` }}
        />
        <Spacer size={64} />
        {featuredWork.length ? (
          <VideoCarousel videos={featuredWork} />
        ) : (
          <Container wide>
            <VideoGrid videos={portfolio} />
          </Container>
        )}
        <Spacer size={96} />
        <BottomCut />
      </Section>
      <Section cuts='topAndBottomCut' style={{ overflowX: 'clip' }}>
        {featuredWork.length > 0 && (
          <>
            <CenteredTextBlock
              data={{
                preHeader: 'Portfolio',
                title: `All ${nickName ? nickName : firstName}'s Work`,
              }}
            />
            <Spacer size={64} />
            <Container wide>
              <PortfolioWrapper>
                <PortfolioStripes>
                  <Stripes08SVG />
                </PortfolioStripes>
                <VideoGrid videos={portfolio} />
              </PortfolioWrapper>
            </Container>
            <Spacer size={96} />
          </>
        )}
        <Container>
          {clients && (
            <>
              <LogoGrid clients={clients} />
              <Spacer size={96} />
            </>
          )}
          <CTABlock
            data={{
              type: 'simple',
              preHeader: 'Get in touch',
              title: `Want to work with ${firstName} ${
                lastName ? lastName : ''
              }?`,
              primaryCTA: true,
              primaryCTAData: {
                label: `Work with ${nickName ? nickName : firstName}`,
                type: 'primary',
                arrow: true,
                path: '/work-with-director',
                state: {
                  directorSlug: slug,
                },
              },
              secondaryCTA: true,
              secondaryCTAData: {
                label: `Contact Us`,
                type: 'secondary',
                arrow: true,
                path: '/contact',
              },
            }}
          />
        </Container>
        {!footerImage && <BottomCut dark />}
      </Section>
      {footerImage && (
        <ImageStripe image={footerImage} centered={centeredFooterImage} />
      )}
    </>
  );
};

DirectorPage.propTypes = {
  data: PropTypes.object, // TODO: Expand
};

// GraphQL Query
export const directorPageQuery = graphql`
  query ($slug: String) {
    sanityDirector(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      firstName
      lastName
      nickName
      relationship
      bio
      headshot {
        ...SanityImageFragment
      }
      awards {
        svgLogo
        awardName
      }
      featuredWork {
        ...SanityVideoFragment
      }
      clients {
        svgLogo
        company
      }
      sizzleLoop {
        ...SanityVideoLoopFragment
      }
      footerImage {
        ...SanityImageFragment
      }
      centeredFooterImage
    }
    allSanityVideo(
      filter: {
        director: { slug: { current: { eq: $slug } } }
        productTags: { elemMatch: { slug: { current: { ne: "corporate" } } } }
      }
    ) {
      nodes {
        ...SanityVideoFragment
      }
    }
  }
`;

export default DirectorPage;
